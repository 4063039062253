<template>
  <v-dialog
    v-model="dialog"
    width="400"
  >
    <v-card :disabled="load">
      <v-card-title>
        {{ nueva ? 'Nueva tipificación' : 'Editar tipificación' }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-8">
        <v-form ref="form">
          <v-row class="pb-2">
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="tipificacion.nombre"
                label="Descripción"
                :rules="[rules.required]"
                validate-on-blur
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-autocomplete
                v-model="tipificacion.comprobante"
                label="Tipo comprobante"
                item-text="nombre"
                item-value="id"
                :rules="[rules.required]"
                :items="tipos_comp.filter(tc => tc.inhabilitado != 1 && tc.tipifica == 1)"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-switch
                v-model="tipificacion.inhabilitado"
                label="Inhabilitada"
                class="mt-0"
                :true-value="1"
                :false-value="null"
                hide-details
                dense
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end py-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal para editar o crear una tipificacion
 * 
 *  Notas: este componente comitea a controlCaja caundo se crea o edita una tipificacion
 *         todos los props son obligatorios
 */
import { mapState } from 'vuex'
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      nueva: false,
      tipificacion: {},
      rules: {
        required: value => !!value || 'Campo requerido',
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('controlCaja/get_tipos')
    this.$store.state.loading = false
  },
  props: {
    value: Boolean,
    p_tipificacion: Object // en caso de ser nueva enviar null
  },
  computed: {
    ...mapState('controlCaja', ['tipos_comp']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    dialog (val) {
      if (val) {
        if (this.p_tipificacion) {
          this.nueva = false
          // crea una copia de la tipificacion para editarla
          this.tipificacion = JSON.parse(JSON.stringify(this.p_tipificacion))
        } else {
          this.nueva = true
          this.tipificacion = {
            nombre: null,
            comprobante: null,
            inhabilitado: null
          }
        }
      } else {
        this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        this.load = true
        if (this.nueva) {
          await this.$store.dispatch('controlCaja/nueva_tipificacion', this.tipificacion)
            .then((res) => {
              this.dialog = false
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success'
              })
              // hace un UPPER del nombre
              this.tipificacion.nombre = this.tipificacion.nombre.toUpperCase()
              // agrega la nueva tipificacion al array del store
              this.tipificacion.id = res.id
              this.$store.commit('controlCaja/add_tipificacion', this.tipificacion)
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        } else {
          // solo edita si realmente modifico la tipificacion
          if (JSON.stringify(this.tipificacion) != JSON.stringify(this.p_tipificacion)) {
            await this.$store.dispatch('controlCaja/editar_tipificacion', this.tipificacion)
              .then((res) => {
                this.dialog = false
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success'
                })
                // hace un UPPER del nombre
                this.tipificacion.nombre = this.tipificacion.nombre.toUpperCase()
                // modifica la tipificacion en el array del store
                this.$store.commit('controlCaja/set_tipificacion', this.tipificacion)
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
          } else {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: 'No se realizaron cambios en la tipificación',
              color: 'warning'
            })
          }
        }
        this.load = false
      }
    }
  }
}
</script>